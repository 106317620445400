.list-sugestoes-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
}


.picklist-sugestoes {
    width: 100%;
    max-width: 1000px;
    height: 100%;
}

@media screen and (min-width: 770px) {
    .picklist-sugestoes {
        padding-left: 14px;
        padding-right: 14px;
    }
}

.picklist-sugestoes .p-picklist-header {
    text-align: center;
}

.picklist-sugestoes .crianca-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;
    width: 100%;
    border-radius: 4px;
}

.picklist-sugestoes .crianca-item .crianca-info {
    font-size: 12px;
}

.crianca-nome {
  display: block;
  margin-bottom: 4px;
}

.crianca-info label {
    font-weight: 600;
}

.crianca-data {
    width: 100%;
    margin-left: 6px;
}

@media screen and (max-width: 576px) {
    .picklist-sugestoes .crianca-item {
        flex-wrap: wrap;
    }

    .picklist-sugestoes .crianca-item .actions, .picklist-sugestoes .crianca-item .crianca-posicao {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .picklist-sugestoes .crianca-item .crianca-posicao {
        margin-bottom: 5px;
    }
}

.total-vagas-container {
    border-radius: 5px;
    background-color: #B3E5FC;
    padding: 10px;
}

.contador-total-vagas {
    color: #044868;
}

.vagas-restantes-container {
    border-radius: 5px;
    background-color: #FFECB3;
    padding: 10px;
}

.contador-vagas-restantes {
    color: #6d5100;
}

.vagas-preenchidas-container {
    border-radius: 5px;
    background-color: #C8E6C9;
    padding: 10px;
}

.contador-vagas-preenchidas {
    color: #224a23;
}

.vagas-excedentes-container {
    border-radius: 5px;
    background-color: #FFCDD2;
    padding: 10px;
}

.contador-vagas-excedentes {
    color: #73000c;
}

.contador-vagas-excedentes span, .contador-vagas-restantes span, .contador-vagas-preenchidas, .contador-total-vagas span {
    font-size: 12px;
}




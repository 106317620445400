.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #fff;
}

.custom-tab-menu {
    position:sticky;
    top:0;
    z-index:100;
}


.layout-main {
    transition: margin-left $transitionDuration;
    padding: 63px .9rem .9rem .9rem;
}

@media (max-width: 400px) {
    .layout-main {
       padding: 54px 0.2rem 0.2rem 0.2rem;
    }
}

.counter-container {
    height: 70px;
}

.counter-label-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    width: 50%;
}

.counter-label-container span {
    font-weight: bold;
}

.counter-value-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 50%;
}

.counter-value-container .count-label { 
    margin-left: 5px;
}

.counter-value-container .count {
    font-size: 28px;
    text-align: center;
}

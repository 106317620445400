/* Typography */
.card {
    background-color: #ffffff;
    padding: 1em;
    border-radius: $borderRadius;
    margin: 0;

    &.card-w-title {
        padding-bottom: 2em;
    }
}

h2, h3 {
    color: #495057;
}


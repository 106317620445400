.permission-dialog .p-dialog-header {
    padding: 5px !important;
}

.permission-dialog {
  width: 300px;
}

.permission-dialog .permission-dialog-content {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.select-permission .p-button {
    width: 120px;

    @media screen and (max-width: 300px) {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
    }
}

.permission-dialog .permission-dialog-content h3 {
    margin-top: 0px;
}

.permission-dialog .permission-dialog-content h3 {
    @media screen and (max-width: 300px) { 
        text-align: center;
    }
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.form-steps {
    border-style: solid;
    border-width: 1px;
    border-color: #e8e8e8;
    border-radius: 5px;     
}

.p-datatable-responsive-demo {
    border-style: solid;
    border-width: 1px;
    border-color: #e8e8e8;
    border-radius: 2px;
    border-bottom: 0px;
}

.datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td,
.datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th
{
    text-align: center;
}

.datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .actions {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 40em) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }
 
    // .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .table-data { 
    //     text-align: right;
    //     float: right;
    //     clear: right;
    //     border: 0 none;
    // }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: block; //inline-block
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }

    .p-datatable-responsive-demo {
        border-style: none;
    }
}
